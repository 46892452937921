<template>
  <ControlButtonDisclosurePopup
    v-if="menuData"
    id="contrast_settings_disclosure_button"
    :inverted="false"
    button-style="button-default"
    button-class="shadow-none outline-offset-[-6px]"
    tabbable-display-check-type="none"
    :aria-label="menuData.localization.accessibilitySettingsLabel"
  >
    <template #icon>
      <IconPzo name="contrast" />
    </template>
    <template #disclosureContent>
      <div class="p-2 text-base space-y-1">
        <div class="font-medium">{{ menuData.localization.fontSizeLabel }}</div>
        <div class="grid gap-2 grid-cols-2 sm:grid-cols-4 w-fit">
          <ControlButton
            id="header_toggle_font_size_3"
            :model-value="fontSize === 'font-size-3'"
            :inverted="false"
            button-style="button-normal"
            class="p-2 w-24"
            :toggle="true"
            :aria-label="menuData.localization.fontSizeLowerLabel"
            @update:model-value="setFontSize(3)"
          >
            {{ menuData.localization.fontSizeLowerShortLabel }}
          </ControlButton>
          <ControlButton
            id="header_toggle_font_size_base"
            :model-value="fontSize === undefined"
            :inverted="false"
            button-style="button-normal"
            class="p-2 w-24"
            :toggle="true"
            :aria-label="menuData.localization.fontSizeDefaultLabel"
            @update:model-value="setFontSize(null)"
          >
            {{ menuData.localization.fontSizeDefaultShortLabel }}
          </ControlButton>
          <ControlButton
            id="header_toggle_font_size_1"
            :model-value="fontSize === 'font-size-1'"
            :inverted="false"
            button-style="button-normal"
            class="p-2 w-24"
            :toggle="true"
            :aria-label="menuData.localization.fontSizeHigherLabel"
            @click="setFontSize(1)"
          >
            {{ menuData.localization.fontSizeHigherShortLabel }}
          </ControlButton>
          <ControlButton
            id="header_toggle_font_size_2"
            :model-value="fontSize === 'font-size-2'"
            :inverted="false"
            button-style="button-normal"
            class="p-2 w-24"
            :toggle="true"
            :aria-label="menuData.localization.fontSizeHighestLabel"
            @click="setFontSize(2)"
          >
            {{ menuData.localization.fontSizeHighestShortLabel }}
          </ControlButton>
        </div>
        <div class="font-medium">{{ menuData.localization.contrastLabel }}</div>
        <div class="grid gap-2 grid-cols-2 sm:grid-cols-4 w-fit">
          <ControlButton
            id="header_toggle_contrast_1"
            :inverted="false"
            button-style="button-normal"
            class="p-2 bg-contrast-white contrast:text-contrast-black w-24"
            :aria-label="menuData.localization.contrastDefaultLabel"
            @click="setContrast(null)"
          >
            <template #icon>
              <IconPzo name="contrast" />
            </template>
          </ControlButton>
          <ControlButton
            id="header_toggle_contrast_2"
            :inverted="false"
            button-style="button-normal"
            class="p-2 bg-contrast-black text-contrast-white border-contrast-white w-24 hover:bg-contrast-black-hover"
            :aria-label="menuData.localization.contrastBlackWhiteLabel"
            @click="setContrast(1)"
          >
            <template #icon>
              <IconPzo name="contrast" />
            </template>
          </ControlButton>
          <ControlButton
            id="header_toggle_contrast_3"
            :inverted="false"
            button-style="button-normal"
            class="p-2 bg-contrast-black text-contrast-yellow border-contrast-yellow w-24 hover:bg-contrast-black-hover"
            :aria-label="menuData.localization.contrastBlackYellowLabel"
            @click="setContrast(2)"
          >
            <template #icon>
              <IconPzo name="contrast" />
            </template>
          </ControlButton>
          <ControlButton
            id="header_toggle_contrast_4"
            :inverted="false"
            button-style="button-normal"
            class="p-2 bg-contrast-yellow text-contrast-black border-contrast-black w-24 hover:bg-contrast-yellow-hover"
            :aria-label="menuData.localization.contrastYellowBlackLabel"
            @click="setContrast(3)"
          >
            <template #icon>
              <IconPzo name="contrast" />
            </template>
          </ControlButton>
        </div>
      </div>
    </template>
  </ControlButtonDisclosurePopup>
</template>
<script setup lang="ts">
import type { MenuDataCommon } from "../../types/menuData";

const { setFontSize, setContrast, fontSize } = useTheme();
interface Props {
  menuData: MenuDataCommon | undefined | null;
}
defineProps<Props>();
</script>

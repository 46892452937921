<template>
  <footer
    class="hidden bg-base-2 rounded shadow-md border border-neutral-7 contrast:border-base-1 p-2 md:items-center md:justify-between md:px-4 lg:flex"
  >
    <span class="text-sm sm:text-center">{{ localization.copyRight }} </span>
    <ul class="mt-3 flex flex-wrap items-center text-sm sm:mt-0">
      <ListItemNuxtLink
        v-if="menuData && menuData.options.pageMenuAvailable"
        to="/sitemap"
        class="mr-4 md:mr-6"
      >
        {{ localization.pageMenu }}
      </ListItemNuxtLink>

      <ListItemNuxtLink
        v-if="menuData && menuData.options.declarationAvailability"
        to="/declaration"
        class="mr-4 md:mr-6"
      >
        {{ menuData.localization.declarationTitle }}
      </ListItemNuxtLink>
      <li>
        <ControlLink
          class="mr-4 md:mr-6"
          :label="localization.privatePolicy"
          target="_blank"
          href="https://www.assecods.pl/polityka-prywatnosci/"
        ></ControlLink>
      </li>

      <li v-if="menuData?.options.contactEmail">
        <ControlLink
          class="mr-4 md:mr-6"
          :label="localization.contact"
          :href="'mailto:' + menuData?.options.contactEmail"
        ></ControlLink>
      </li>
    </ul>
  </footer>
</template>
<script setup lang="ts">
const localization = useGlobalLocalization();
interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  menuData: any;
}
defineProps<Props>();
</script>

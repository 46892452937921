<template>
  <li>
    <NuxtLink
      :to="to"
      class="block px-2 py-0.5 group outline-none"
      @click="hideMenuUpToLg"
    >
      <div
        class="cursor-pointer text-base-2 contrast:text-base-1 w-full block p-2 rounded group-focus-visible:outline group-focus-visible:outline-2 group-focus-visible:outline-base-2 data-active:bg-base-2 data-active:contrast:bg-base-1 data-active:text-primary-1 data-active:contrast:text-base-2 data-active:group-hover:bg-base-2 data-active:group-hover:contrast:bg-c-primary-0 data-active:group-focus-visible:outline-offset-2 group-hover:bg-primary-3 contrast:group-hover:bg-c-primary-0 contrast:group-hover:text-base-2"
        :data-active="isActive === true"
      >
        <slot name="label"></slot>
      </div>
    </NuxtLink>
    <ul class="w-full">
      <slot name="submenus"></slot>
    </ul>
  </li>
</template>

<script setup lang="ts">
import type { RouteLocationRaw } from "#vue-router";
import type { MenuCode } from "~/types/menuData";
interface Props {
  to: RouteLocationRaw;
  code: MenuCode;
}
const props = defineProps<Props>();

const { activeMenu, hideMenuUpToLg } = await useMenu();
const isActive = computed(() => {
  return activeMenu.value === props.code;
});
</script>

<template>
  <div
    class="lg:my-1 lg:ml-1 data-[menu-state='false']:ml-0 overflow-visible"
    :data-menu-state="menuState"
  >
    <nav
      :id="props.id"
      ref="menuRef"
      class="absolute z-40 h-full w-72 shrink-0 overflow-auto bg-primary-1 bg-no-repeat transition-[left] duration-300 sm:w-80 lg:contrast:border-base-1 contrast:bg-base-2 lg:border lg:rounded pt-1 lg:shadow-md"
      :class="menuClasses()"
    >
      <slot />
    </nav>
    <ScreenDisable
      v-if="menuState"
      class="z-30 lg:hidden"
      @click="toggle()"
    ></ScreenDisable>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  toggleContainer: {
    type: String,
    required: true,
  },
});

const enabledClasses = "left-0 lg:relative";
const disabledClasses = "-left-80";
const defaultClasses = "-left-80 lg:relative lg:left-0";

const menuRef = ref<HTMLElement | null>(null);
const { menuState, menuElement, toggle } = await useMenu();

onMounted(() => {
  menuElement.value = menuRef.value;
});

function menuClasses() {
  if (menuState.value === true) {
    return enabledClasses;
  } else if (menuState.value === false) {
    return disabledClasses;
  }
  return defaultClasses;
}
</script>

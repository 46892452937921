<template>
  <div
    id="layout"
    class="flex h-screen flex-col items-center bg-base-2 text-base-1"
  >
    <NuxtLoadingIndicator class="bg-primary-1" color="" />
    <HeaderContent />
    <PrivacyPolicyCookie></PrivacyPolicyCookie>
    <SessionTimeoutDialog />
    <div
      class="relative overflow-hidden flex w-full grow flex-row sm:bg-neutral-8 contrast:sm:bg-c-secondary-0 data-[menu-state='true']:gap-1 data-[menu-state='false']:gap-0 lg:gap-1"
      :data-menu-state="menuState"
    >
      <MenuToggleable id="side_menu" toggle-container="#toggle_container">
        <MenuContent></MenuContent>
      </MenuToggleable>
      <main
        ref="htmlMain"
        class="flex gap-2 grow flex-col sm:rounded sm:overflow-visible sm:p-1"
        :data-with-border="withBorder === true"
      >
        <div
          id="main_content"
          class="flex grow w-full bg-base-2 sm:rounded h-5/6 sm:data-[with-border='true']:border data-[with-border='true']:border-neutral-7 contrast:data-[with-border='true']:border-base-1 data-[with-border='true']:shadow-md sm:overflow-clip"
          :data-with-border="withBorder === true"
        >
          <div
            class="w-full flex flex-col items-center data-[with-border='false']:overflow-visible data-[with-border='true']:overflow-auto data-[with-border='true']:px-1 sm:data-[with-border='true']:px-2 data-[with-border='true']:pb-12 sm:data-[with-border='true']:pb-2"
            :data-with-border="withBorder === true"
          >
            <slot />
          </div>
        </div>
        <DefaultFooter :menu-data="menuData"></DefaultFooter>
      </main>
    </div>
  </div>
</template>
<script setup lang="ts">
interface Props {
  withBorder: boolean;
}

defineProps<Props>();

const htmlMain = ref<HTMLElement>();
const router = useRouter();
router.options.scrollBehavior = () => {
  htmlMain.value?.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
};
const { menuState, data: menuData } = await useMenu();
useNoscript();
useFavicon();
</script>

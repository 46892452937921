<template>
  <Transition>
    <div
      v-if="statusResult && visible()"
      class="absolute left-0 top-0 z-20 flex w-screen items-center p-1 shadow-lg data-[fixed='true']:fixed data-[fixed='true']:z-50 sm:left-auto sm:right-0 sm:m-5 sm:max-w-md sm:rounded"
      :class="colorClass()"
      :data-fixed="statusResult.fixed"
    >
      <div class="grow overflow-hidden">
        <div class="overflow-auto align-middle">
          <IconPzo
            v-if="
              statusResult.type === 'ERROR' || statusResult.type === 'error'
            "
            name="close"
            class="ml-2 mt-1 rounded-full text-supporting-12 h-6 w-6 float-left border-2 border-supporting-13 bg-base-2"
          />
          <IconPzo
            v-else-if="
              statusResult.type === 'WARNING' || statusResult.type === 'warning'
            "
            name="exclamation"
            class="ml-2 mt-1 rounded-full text-supporting-22 h-6 w-6 float-left border-2 border-supporting-22 bg-base-2"
          />
          <IconPzo
            v-else-if="
              statusResult.type === 'SUCCESS' || statusResult.type === 'success'
            "
            name="done"
            class="ml-2 mt-1 rounded-full text-supporting-32 h-6 w-6 float-left border-2 border-supporting-33 bg-base-2"
          />
          <IconPzo
            v-else
            name="info-no-circle"
            class="ml-2 mt-1 rounded-full text-supporting-40 h-6 w-6 float-left border-2 border-supporting-42 bg-base-2"
          ></IconPzo>
          <ControlButton
            id="status_result_dismiss"
            :inverted="false"
            button-style="button-compact"
            class="ml-auto self-center rounded bg-transparent focus-visible:outline-current float-right"
            :class="hoverClass()"
            @click="dismiss()"
          >
            <template #icon>
              <IconPzo name="close" />
            </template>
          </ControlButton>
          <p v-if="titleText()" class="px-2 py-1 font-medium truncate">
            {{ titleText() }}
          </p>
        </div>
        <p v-if="descriptionText()" class="px-2 py-1">
          {{ descriptionText() }}
        </p>
      </div>
    </div>
    <div v-else></div>
  </Transition>
</template>
<script setup lang="ts">
import type { StatusResult } from "common-components-nuxt-types/common/statusResult";

const statusResult = useState<StatusResult | undefined>("statusResult");
function dismiss() {
  statusResult.value = undefined;
}

const globalLocalization = useGlobalLocalization();

function colorClass() {
  if (!statusResult.value) {
    return "";
  }
  if (
    statusResult.value.type === "ERROR" ||
    statusResult.value.type === "error"
  ) {
    return "bg-supporting-19 text-base-1 border-supporting-14 border-b-2 border-t-0 border-l-0 border-r-0 sm:border-t-2 sm:border-l-2 sm:border-r-2 contrast:bg-c-secondary-0";
  }
  if (
    statusResult.value.type === "WARNING" ||
    statusResult.value.type === "warning"
  ) {
    return "bg-supporting-28 text-base-1 border border-supporting-25 border-b-2 border-t-0 border-l-0 border-r-0 sm:border-t-2 sm:border-l-2 sm:border-r-2 contrast:bg-c-secondary-0";
  }
  if (
    statusResult.value.type === "SUCCESS" ||
    statusResult.value.type === "success"
  ) {
    return "bg-supporting-39 text-base-1 border border-supporting-35 border-b-2 border-t-0 border-l-0 border-r-0 sm:border-t-2 sm:border-l-2 sm:border-r-2 contrast:bg-c-secondary-0";
  }

  return "border border-supporting-45 bg-supporting-49 border-b-2 border-t-0 border-l-0 border-r-0 sm:border-t-2 sm:border-l-2 sm:border-r-2 contrast:bg-c-secondary-0";
}

function hoverClass() {
  if (!statusResult.value) {
    return "";
  }
  if (
    statusResult.value.type === "ERROR" ||
    statusResult.value.type === "error"
  ) {
    return "hover:bg-supporting-17";
  }
  if (
    statusResult.value.type === "WARNING" ||
    statusResult.value.type === "warning"
  ) {
    return "hover:bg-supporting-26";
  }
  if (
    statusResult.value.type === "SUCCESS" ||
    statusResult.value.type === "success"
  ) {
    return "hover:bg-supporting-37";
  }

  return "hover:bg-supporting-47";
}

function visible() {
  return (
    statusResult.value &&
    (statusResult.value.title || statusResult.value.description)
  );
}

function titleText() {
  if (
    statusResult.value &&
    statusResult.value.title &&
    statusResult.value.description
  ) {
    return statusResult.value.title;
  }
  if (!statusResult.value) {
    return "";
  }
  if (
    statusResult.value.type === "ERROR" ||
    statusResult.value.type === "error"
  ) {
    return globalLocalization.statusResultDefaultErrorTitle;
  }
  if (
    statusResult.value.type === "WARNING" ||
    statusResult.value.type === "warning"
  ) {
    return globalLocalization.statusResultDefaultWarningTitle;
  }
  if (
    statusResult.value.type === "SUCCESS" ||
    statusResult.value.type === "success"
  ) {
    return globalLocalization.statusResultDefaultSuccessTitle;
  }
  return "";
}

function descriptionText() {
  if (statusResult.value && statusResult.value.description) {
    return statusResult.value.description;
  }
  if (statusResult.value && statusResult.value.title) {
    return statusResult.value.title;
  }
  return "";
}
</script>

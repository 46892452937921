<template>
  <ControlButton
    id="header_toggle_menu"
    :inverted="false"
    class="shadow-none outline-offset-[-6px]"
    button-style="button-default"
    :aria-label="label"
    @click="toggle()"
  >
    <template #icon>
      <IconPzo name="menu" class="text-xl md:text-2xl" />
    </template>
  </ControlButton>
</template>
<script setup lang="ts">
interface Props {
  label: string;
}

defineProps<Props>();

const { toggle } = await useMenu();
</script>

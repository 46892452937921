<template>
  <li
    class="flex w-full cursor-pointer items-center text-base-2 contrast:text-base-1 px-2 py-0.5 group"
    :data-active="isActive === true"
  >
    <NuxtLink
      :to="to"
      class="w-full p-2 rounded focus-visible:outline focus-visible:outline-2 focus-visible:outline-base-2 data-active:bg-base-2 contrast:data-active:bg-base-1 data-active:text-primary-1 contrast:data-active:text-base-2 data-active:group-hover:bg-base-2 data-active:focus-visible:outline-offset-2 group-hover:bg-primary-3 contrast:group-hover:bg-c-primary-0 contrast:group-hover:text-base-2"
      :data-active="isActive === true"
      @click="hideMenuUpToLg"
    >
      <slot></slot>
    </NuxtLink>
  </li>
</template>

<script setup lang="ts">
import type { RouteLocationRaw } from "#vue-router";
import type { MenuCode } from "~/types/menuData";
interface Props {
  to: RouteLocationRaw;
  code: MenuCode;
}
const props = defineProps<Props>();

const { activeMenu, hideMenuUpToLg } = await useMenu();
const isActive = computed(() => {
  return activeMenu.value === props.code;
});
</script>

<template>
  <div class="flex h-full flex-col">
    <ul class="grow">
      <MenuMaster to="/" code="home">
        <template #label>
          <span class="flex gap-2">
            <IconPzo name="home" class="text-lg shrink-0" />
            {{ localization.homePage }}
          </span>
        </template>
        <template #submenus>
          <MenuItem
            v-for="(menu, index) in menuList"
            :key="index"
            :to="menu.to"
            :code="menu.code"
          >
            <div class="flex gap-2">
              <IconPzo
                v-if="menu.icon"
                :name="menu.icon"
                class="text-lg shrink-0"
              />
              {{ menu.text }}
            </div>
          </MenuItem>
        </template>
      </MenuMaster>
    </ul>
    <div v-if="showDomainSelect()" class="mx-2">
      <DevDomainSelect class="mx-0" :inverted="true" />
    </div>
    <a
      href="https://www.assecods.pl/oferta/oswiata"
      class="text-center"
      target="_blank"
    >
      <img
        v-if="contrast === 'contrast-3'"
        src="~common-assets/logotypes/assecods_black.svg"
        class="my-4 inline-block h-8"
        alt="Logo AssecoDS"
      />
      <img
        v-else
        src="~common-assets/logotypes/assecods_white.svg"
        class="my-4 inline-block h-8"
        alt="Logo AssecoDS"
      />
    </a>
  </div>
</template>

<script setup lang="ts">
const localization = useGlobalLocalization();

const { contrast } = useTheme();
const { menuList } = await useMenu();
const config = useRuntimeConfig();

function showDomainSelect() {
  return config.public.devDomain === true;
}
</script>

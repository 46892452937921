<template>
  <ControlButton
    v-if="
      !loginReady && menuData && menuData.options.loginOrRegistrationEnabled
    "
    id="header_go_to_login"
    :inverted="false"
    button-style="button-default"
    class="shadow-none md:text-lg outline-offset-[-6px]"
    @click="navigateTo('/login')"
  >
    {{ menuData.localization.loginLabel }}
  </ControlButton>
  <ControlButton
    v-else-if="menuData && menuData.options.loginOrRegistrationEnabled"
    id="header_logout"
    :inverted="false"
    button-style="button-default"
    class="shadow-none md:text-lg outline-offset-[-6px]"
    @click="logout()"
  >
    {{ menuData.localization.logoutLabel }}
  </ControlButton>
</template>
<script setup lang="ts">
const { data: menuData } = await useMenu();
const { logout, loginReady } = await useAuth();
</script>

<template>
  <div class="flex flex-row items-center pr-4 pl-2 text-primary-1 gap-x-2">
    <div
      class="rounded border border-neutral-7 contrast:border-base-1 contrast:bg-c-secondary-0 bg-base-2 shadow-inner h-10 w-10 flex items-center justify-center"
    >
      <IconPzo name="person" class="md:text-2xl" />
    </div>
    <div class="p-0 text-right">
      <div v-if="session && isWkSession && session.wkSessionData">
        {{ session.wkSessionData.firstName }}
        {{ session.wkSessionData.lastName }}
      </div>
      <div v-else-if="student && !isWkSession">
        {{ student.firstName }}
        {{ student.lastName }}
      </div>
      <div v-if="student && !isWkSession" class="text-xs">
        {{ student.pesel }}
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
const { student, isWkSession, session } = await useAuth();
</script>

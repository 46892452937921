<template>
  <div
    v-if="visible"
    class="fixed inset-0 z-50 flex items-center justify-center"
  >
    <ScreenDisable class="z-30"></ScreenDisable>
    <div
      v-if="authenticationData"
      class="z-50 flex flex-col items-center justify-center gap-2 rounded border border-neutral-8 bg-base-2 px-4 pb-4 text-xl shadow-lg md:px-8 md:pb-8"
    >
      <h1>{{ authenticationData.localization.sessionTimeoutWarningText }}</h1>
      <div class="relative mb-6 h-48 w-48 text-primary-1 md:h-64 md:w-64">
        <svg
          class="-scale-x-100 transform"
          viewBox="0 0 100 100"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g class="fill-none stroke-none">
            <path
              :stroke-dasharray="((counter / 10) * 283).toFixed(0) + ' 283'"
              class="timer-path-remaining text-primary-1"
              d="M 50, 50 m -45, 0 a 45,45 0 1,0 90,0 a 45,45 0 1,0 -90,0"
            ></path>
          </g>
        </svg>
        <div
          class="absolute inset-0 flex flex-col items-center justify-center gap-2"
        >
          <span class="text-5xl md:text-7xl">
            {{ counter }}
          </span>
        </div>
      </div>
      <div class="w-full">
        <ControlButton
          id="session_timeout_refresh_session"
          button-style="button-normal"
          class="mb-2 w-full"
          @click="refreshSession"
        >
          {{ authenticationData.localization.stayIn }}
        </ControlButton>
        <ControlButton
          id="session_timeout_logout"
          button-style="button-normal"
          :inverted="false"
          class="w-full"
          @click="logout"
        >
          {{ authenticationData.localization.logout }}
        </ControlButton>
      </div>
    </div>
  </div>
  <div v-else></div>
</template>
<script setup lang="ts">
const SECONDS_TO_REACT = 10;
const MILISECONDS_TO_IDLE = 60 * 1000;
const MILISECONDS_COUNTER_TICK_RATE = 1000;
const MILISECONDS_BACKEND_SESSION_REFRESH_INTERVAL = 60 * 1000;
const MILISECONDS_SESSION_TIMEOUT_DIALOG_APPEARANCE = 15 * 60 * 1000;

const visible = ref(false);
const counter = ref(SECONDS_TO_REACT);

const { refresh: refreshSessionApi } = useApi("/api/user/refreshSession", {
  immediate: false,
});
const { authenticationData } = await useAuthenticationData();
const auth = await useAuth();
const { isAuthenticated } = auth;
const { start, stop } = useTimeoutFn(
  () => {
    if (isAuthenticated.value) {
      visible.value = true;
      counter.value = SECONDS_TO_REACT;
      resume();
    }
  },
  MILISECONDS_SESSION_TIMEOUT_DIALOG_APPEARANCE,
  { immediate: false },
);
const { idle } = useIdle(MILISECONDS_TO_IDLE);

const { pause, resume } = useIntervalFn(
  () => {
    if (counter.value > 0) {
      counter.value--;
    } else {
      logout();
    }
  },
  MILISECONDS_COUNTER_TICK_RATE,
  { immediate: false },
);

useIntervalFn(
  () => {
    if (isAuthenticated.value && !idle.value && !visible.value) {
      refreshSession();
    }
  },
  MILISECONDS_BACKEND_SESSION_REFRESH_INTERVAL,
  { immediate: true },
);

watch(idle, (newValue) => {
  if (isAuthenticated.value) {
    if (!newValue && !visible.value) {
      refreshSession();
    } else {
      start();
    }
  } else {
    restartTimers();
  }
});

function restartTimers() {
  stop();
  pause();
  visible.value = false;
  counter.value = SECONDS_TO_REACT;
}

function logout() {
  pause();
  restartTimers();
  auth.logout();
}

function refreshSession() {
  refreshSessionApi();
  restartTimers();
}
</script>
<style scoped>
.timer-path-remaining {
  stroke-width: 5;
  stroke-linecap: round;
  transform: rotate(90deg);
  transform-origin: center;
  transition: 1s linear all;
  stroke: currentColor;
}
</style>

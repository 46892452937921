<template>
  <header class="z-40 w-full border-b border-primary-1 bg-base-2 shadow">
    <div v-if="menuData" class="relative flex flex-row items-center">
      <div class="flex flex-row">
        <MenuToggleButton
          :label="menuData.localization.menuToggleTooltip"
          class="self-center ml-1"
        />
        <div
          class="self-stretch w-0 border-l border-neutral-7 my-1 mx-1 contrast:border-base-1"
        ></div>
      </div>
      <div class="ml-4 hidden lg:inline" :class="logoClasses">
        <h1 class="my-0">
          <span class="text-base-1">
            {{ menuData?.options.moduleMainName }}
          </span>
        </h1>
        <h2 class="float-right my-0">
          <span class="text-primary-2">
            {{ menuData?.options.moduleSubName }}
          </span>
        </h2>
      </div>

      <div class="shrink-0 grow"></div>
      <div class="ml-4 hidden h-12 p-0.5 sm:block md:h-14">
        <LoadingImage
          class="h-full w-full contrast:hidden contrast:text-base-1"
          :src="
            menuData && menuData.options.moduleLogoPath
              ? menuData.options.moduleLogoPath
              : ''
          "
          :alt="
            menuData && menuData.localization.altImageText
              ? menuData.localization.altImageText
              : ''
          "
        />
        <LoadingImage
          class="h-full w-full hidden contrast:block contrast:text-base-1"
          :src="
            menuData && menuData.options.moduleContrastLogoPath
              ? menuData.options.moduleContrastLogoPath
              : ''
          "
          :alt="
            menuData && menuData.localization.altImageText
              ? menuData.localization.altImageText
              : ''
          "
        />
      </div>
      <h2
        v-if="menuData?.options.cityName"
        class="float-right my-0 hidden sm:inline"
      >
        <span class="text-primary-2">
          {{ menuData?.options.cityName }}
        </span>
      </h2>

      <div class="flex flex-row items-stretch self-stretch">
        <div
          class="self-stretch w-0 border-l border-neutral-7 my-1 mx-1 contrast:border-base-1"
        ></div>
        <HeaderThemeButtons class="self-center" :menu-data="menuData" />
        <div
          v-if="isAuthenticated && (!isMMSession || student)"
          class="hidden sm:flex self-stretch w-0 border-l border-neutral-7 my-1 mx-1 contrast:border-base-1"
        ></div>
        <UserInfo
          v-if="isAuthenticated && (!isMMSession || student)"
          class="hidden sm:flex self-stretch"
        ></UserInfo>
        <div
          class="self-stretch w-0 border-l border-neutral-7 my-1 mx-1 contrast:border-base-1"
        ></div>
        <HeaderAuthButtons class="self-center mr-1" />
      </div>
    </div>
  </header>
</template>
<script setup lang="ts">
const { data: menuData, menuState } = await useMenu();
const { isAuthenticated, isMMSession, student } = await useAuth();

const logoClasses = computed(() => {
  if (menuState.value || menuState.value === undefined) {
    return "xl:ml-64";
  }
});
</script>
